import PageRender from '../../models/PageRender';
import { productService, dictionaryHelper, financeAccountService } from '@/main';
import Component from 'vue-class-component';
import Vue from 'vue';
import PriceRule from '../../models/PriceRule';
import to from 'await-to-js';
import Product from '../../models/Product';
import Site from '../../models/site';
import { $router } from '@/router';
import { BModal } from 'bootstrap-vue';
import { sitesModule } from '@/store/modules/site';
import GridWrapperSearch from '@prd/shared-ui/dist/services/components/Grid/models/GridWrapperSearch';

@Component
export default class ProductsComponent extends PageRender {
    public products: Product[] = [];
    public isLoading: boolean = true;
    public newProductModel: PriceRule = new PriceRule();
    public site: Site = {} as Site;
    public dictionary = dictionaryHelper;
    public financeAccounts: any[] = [];
    public indexPercentage: number = 0;
    public search = new GridWrapperSearch({ properties: ['description'] });

    public productColumns = [
        { field: 'productId', cell: this.renderProductLink, title: 'Naam' },
        { cell: this.renderDiscountCostField, title: 'Korting/kosten', filterable: false },
        { field: 'mergeInAccoPrice', cell: this.renderInAccoPrice, title: 'In acco prijs?' },
        { field: 'productType', title: 'Type', cell: this.renderProductType },
        { field: 'financeAccountId', cell: this.renderFinanceAccount, title: 'Grootboekrekening' },
        { field: 'optional', cell: this.renderVerplicht, title: 'Is verplicht' },
        { field: 'registerApplicable', cell: this.renderRegisterApplicable, title: 'Kassaproduct?' },
        { cell: this.renderActions, title: 'Actions', width: 125 },
    ];

    public $refs!: {
        indexModal: BModal;
    };

    public async created() {
        await sitesModule.fetchSitesIfNeeded();
        await this.loadProducts();
        this.financeAccounts = await this.loadFinanceAccounts();
        this.isLoading = false;
    }

    public async loadFinanceAccounts() {
        const [err, response] = await to(financeAccountService.getAccounts(this.siteId));
        if (err) {
            return this.showError('Mislukt om de grootboekrekeningen op te halen');
        }

        return response.data;
    }

    public async loadProducts() {
        const [err, response] = await to(productService.getProducts(this.siteId));
        if (err) {
            this.showError('Producten & kortingen ophalen mislukt');
        }
        return (this.products = response.data);
    }

    public renderProductLink(h: any, a, row) {
        const route = $router.resolve({
            name: 'site-product',
            params: { siteId: this.siteId.toString(), productId: row.dataItem.productId, siteKey: this.siteKey },
        });

        const props = { text: row.dataItem.description, url: route.href };

        return h(Vue.component('grid-router-link'), { props });
    }

    public renderProductType(h: any, a, row) {
        const list = dictionaryHelper.get('ProductTypes');

        return h('td', list[row.dataItem.productType]);
    }

    public renderActions(h: any, a, row): any {
        const actions = [
            { title: 'Bewerk', function: this.editProduct },
            {
                title: 'Verwijder',
                function: this.deleteProduct,
            },
        ];

        const item = row.dataItem;
        const props = { item, actions };

        return h(Vue.component('grid-actions'), { props });
    }

    public editProduct(item) {
        this.$router.push({
            name: 'site-product',
            params: { siteId: this.siteId.toString(), productId: item.productId, siteKey: this.siteKey },
        });
    }

    public async deleteProduct(item) {
        this.showPending('Product verwijderen...');
        const [err, response] = await to(productService.deleteProduct(item.productId, this.siteId));
        if (err) {
            return this.clearAndShowError('Mislukt om het product te verwijderen', err);
        }
        await this.loadProducts();

        this.clearAndShowSuccess('Product verwijderd');
        return response.data;
    }

    public renderDiscountCostField(h: any, a, row) {
        const item = row.dataItem;
        let value: string;

        if (item.discountAmount) {
            value = kendo.toString(item.discountAmount, 'c2');
        } else if (item.discountPercentage) {
            value = kendo.toString(item.discountPercentage / 100, 'p');
        }

        return h('td', value ? value : '-');
    }

    public renderFinanceAccount(h: any, a: any, row: any): any {
        const financeAccount = this.financeAccounts.find((x) => x.financeAccountId === row.dataItem.financeAccountId);

        return h('td', [typeof financeAccount !== 'undefined' ? financeAccount.name : '-']);
    }

    public renderInAccoPrice(h: any, a: any, row: any): any {
        return h('td', [typeof row.dataItem.mergeInAccoPrice !== 'undefined' && row.dataItem.mergeInAccoPrice === true ? 'Ja' : 'Nee']);
    }

    public renderVerplicht(h: any, a: any, row: any): any {
        return h('td', [typeof row.dataItem.optional !== 'undefined' && row.dataItem.optional === true ? 'Nee' : 'Ja']);
    }

    public renderRegisterApplicable(h: any, a: any, row: any): any {
        return h('td', [typeof row.dataItem.registerApplicable !== 'undefined' && row.dataItem.registerApplicable === true ? 'Ja' : 'Nee']);
    }

    public addProduct() {
        const params = {
            productId: 'nieuw',
            siteKey: this.siteKey,
        };

        this.$router.push({
            name: 'site-product',
            params,
        });
    }

    public showIndexModal() {
        this.$refs.indexModal.show();
    }

    public cancel() {
        this.$refs.indexModal.hide();
    }

    public async indexProducts() {
        this.showPending('Producten indexeren...');
        const [err, response] = await to(productService.indexProducts(this.indexPercentage, this.siteId));
        if (err) {
            return this.clearAndShowError('Mislukt om de producten op te indexeren', err);
        }
        await this.loadProducts();

        this.clearAndShowSuccess('Indexatie geslaagd');
        this.$refs.indexModal.hide();
        return response.data;
    }
}
