import PageRender from '../../models/PageRender';
import { priceRuleService } from '@/main';
import Component from 'vue-class-component';
import Vue from 'vue';
import { BModal } from 'bootstrap-vue';
import PriceRule from '../../models/PriceRule';
import to from 'await-to-js';
import Site from '../../models/Site';
import { $router } from '@/router';
import { sitesModule } from '@/store/modules/site';
import GridWrapperSearch from '@prd/shared-ui/dist/services/components/Grid/models/GridWrapperSearch';

@Component
export default class PriceRulesComponent extends PageRender {
    public rules: any[] = [];
    public isLoading: boolean = true;
    public newPriceRuleModel: PriceRule = new PriceRule();
    public sites: Site[] = null;
    public reload: number = 0;
    public priceRuleColumns = [
        { field: 'name', title: 'Naam', filterable: false, cell: this.renderPriceRuleLink },
        { field: 'siteId', cell: this.renderSystemPriceRule, title: 'Aantal parken gekoppeld', filterCell: 'boolean', sortable: false },
        { field: 'isActive', title: 'Is actief', filter: 'bool', cell: this.renderIsActive, width: 150 },
        { title: 'Acties', cell: this.renderActions, field: 'actions', filter: null, filterable: false, width: 150, sortable: false },
    ];
    public search = new GridWrapperSearch({ properties: ['name'] });

    public $refs!: {
        newPriceRuleModal: BModal;
    };

    public settings = {
        valueField: 'eventTypeId',
        labelField: 'name',
        options: this.rules,
        openOnFocus: true,
        searchField: ['name'],
        placeholder: 'Selecteer een type',
    };

    public async created() {
        await sitesModule.fetchSitesIfNeeded();
        this.rules = await this.loadPriceRules();
        this.isLoading = false;
    }

    public get site() {
        return sitesModule.activeSite;
    }

    public async loadPriceRules() {
        const [err, response] = await to(priceRuleService.getPriceRules(this.site.siteId));
        if (err) {
            this.showError('Business regels ophalen mislukt');
        }
        return response.data;
    }

    public getPriceRuleRouteName() {
        return 'site-price-rule';
    }

    public addPriceRule() {
        const params = {
            priceRuleKey: 'nieuw',
            siteKey: this.siteKey,
        };

        $router.push({
            name: this.getPriceRuleRouteName(),
            params,
        });
    }

    public renderActions(h, a, row): any {
        const item = row ? row.dataItem : {};
        const actions = [
            { title: 'Bewerk', function: this.editRule },
            { title: 'Verwijder', function: this.deleteRule },
        ];

        const props = { actions, item };
        return h(Vue.component('grid-actions'), { props });
    }

    public renderPriceRuleLink(h: any, a, row) {
        const params = {
            priceRuleKey: row.dataItem.priceRuleId,
            siteKey: this.siteKey,
        };

        const route = $router.resolve({
            name: this.getPriceRuleRouteName(),
            params,
        });

        const props = {
            text: row.dataItem.name,
            url: route.href,
        };

        return h(Vue.component('grid-router-link'), { props });
    }

    public async deleteRule(item) {
        this.showPending('Prijsregel verwijderen...');
        const [err, response] = await to(priceRuleService.deletePriceRule(item.priceRuleId, this.site.siteId));
        if (err) {
            return this.clearAndShowError('Mislukt om de prijsregel te verwijderen', err);
        }
        this.rules = await this.loadPriceRules();
        this.reload++;

        this.clearAndShowSuccess('Prijsregel verwijderd');
        return response.data;
    }

    public editRule(item) {
        const params = {
            priceRuleKey: item.priceRuleId,
            siteKey: this.siteKey,
        };

        $router.push({
            name: this.getPriceRuleRouteName(),
            params,
        });
    }

    public renderSystemPriceRule(h: any, a: any, row: any): any {
        return h('td', [row.dataItem.siteIds.length]);
    }

    public renderIsActive(h: any, a: any, row: any) {
        return h('td', [row.dataItem.isActive ? 'Ja' : 'Nee']);
    }

    public renderSiteName(h: any, a: any, row: any): any {
        const site = this.sites.find((s) => s.siteId === row.dataItem.siteId);

        return h('td', [typeof site === 'undefined' ? '-' : site.name]);
    }
}
