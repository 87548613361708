import { FacilityInterface } from './Interfaces';
import FacilityGroup from './FacilityGroup';

export default class Facility implements FacilityInterface {
    public facilityId: number;
    public facilityGroupId: number;
    public name: string;
    public description: string;
    public icon: string;
    public isSystemFacility: boolean;
    public group: FacilityGroup = new FacilityGroup();

    constructor(obj: any = {}) {
        Object.assign(this, obj);
    }
}
